import React from 'react';
import { Link } from 'gatsby';
import '../style/footer.scss';

export default function Footer() {
  return (
    <div id='footer'>
      <div id='courtesy-links-container'>
        <div id='page-links'>
          <div id='col-01'>
            <div><Link to='/'>Home</Link></div>
            <div><Link to='/destinations'>Destinations</Link></div>
            <div><Link to='/practice_acquisition'>Practice Acquisition</Link></div>
          </div>
          <div id='col-02'>
            <div><Link to='/careers'>Careers</Link></div>
            <div><Link to='/#about_us'>About Us</Link></div>
            <div><Link to='/sunset_opportunities'>Sunset Opportunities</Link></div>
          </div>
        </div>
        <div id='social-links-container'>
          <ul id='social-links'>
            <li>
              <a href='https://twitter.com/TobyRichey'>
                <i className="fa fa-twitter" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/company/toby-richey-&-associates/'>
                <i className="fa fa-linkedin" aria-hidden="true"></i>
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/tobyrichey.associates'>
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div id='inquire-container'>
        <div id='inquire'>
          Contact Toby Richey & Associates<br />
          <a href='mailto:info@tobyrichey.com'>info@tobyrichey.com</a>
        </div>
      </div>
      <div id='copyright-container' >
        <div id='center-column' className='col-xs-12'>
          <div id='copyright-info'>
            © {(new Date().getFullYear())} Toby Richey & Associates. All Rights Reserved.
          </div>
          <div id='site-design-info'>
            <a href='http://www.loblein.com'>
              Site design by Eldon Loblein | loblein.com
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
