import React from 'react';
import '../style/reset.scss';


class DecoBorder extends React.Component {

  render() {
    return (
      <div className='deco-border'>
        <div className='border-container'>
          <div className='deco-gold'></div>
        </div>
      </div>
    )
  }
}

export default DecoBorder;
