import React, { Component } from "react"
import { Link } from 'gatsby';
import "../style/header.scss"
import "../style/bootstrap.min.css"
// import icon from "../images/toby_richey_icon.png";
import logo from '../images/icon_small.jpg';

class Header extends Component {
  state = {
    open: false,
    style: 'collapse navbar-collapse',
    links: [
      {
        id: 1,
        path: '/destinations',
        text: 'Destinations'
      },
      {
        id: 2,
        path: '/practice_acquisition',
        text: 'Practice Acquisition'
      },
      {
        id: 3,
        path: '/sunset_opportunities',
        text: 'Sunset Opportunities'
      },
      {
        id: 4,
        path: '/#about_us',
        text: 'About Us'
      },
      {
        id: 5,
        path: '/careers',
        text: 'Careers'
      }
    ]
  }

  navbarHandler = () => {
    if (this.state.open === false) {
      this.setState({
        open: true,
        style: "collapse navbar-collapse show slide"
      });

    } else {
      this.setState({
        open: false,
        style: "collapse navbar-collapse"
      })
    }
  }

  handleLinkClick = () => {
    this.setState({
      open: false,
      style: "collapse navbar-collapse"
    })
  }

  componentDidMount() {
    var thisComponent = this;
    function handleResize() {
      thisComponent.setState({
        open: false,
        style: 'collapse navbar-collapse'
      })
    }
    window.addEventListener('resize', handleResize);
  }

  render() {
    return <nav className='custom-header navbar fixed-top navbar-expand-lg navbar-light'>
      <Link to='/' className='navbar-brand'>
        <img id='header-logo' alt='logo' src={logo} />
      </Link>
      <button className='navbar-toggler' type='button' onClick={this.navbarHandler}>
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className={this.state.style}>
        <ul className='navbar-nav ml-auto'>
          {
            this.state.links.map(link => {
              return (
                <li className='nav-item' key={link.id}>
                  <Link to={link.path} onClick={this.handleLinkClick} className='nav-link text-capitalize'>
                    {link.text}
                  </Link>
                </li>
              )
            })
          }
          <div className='social-icons'>
            <ul>
              <li>
                <a href='https://twitter.com/TobyRichey'>
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href='https://www.linkedin.com/company/toby-richey-&-associates/'>
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/tobyrichey.associates'>
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </nav>
  }
}

export default Header;
