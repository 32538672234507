import React from "react";
import "../style/reset.scss";
import '../style/layout.scss';
import Header from './header';
import Footer from './footer';

export default ({ children }) =>
  <div id='page-layout'>
    <Header></Header>
      {children}
    <Footer></Footer>
  </div>
